@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.link-underline {
		@apply duration-500 transition-colors h-max relative underline underline-offset-[0.25em] decoration-[3px] decoration-pink hover:decoration-yellow;
	}

	.tag-pill {
		@apply px-2.5 py-1 text-xs leading-none border-2 rounded-full lg:text-sm border-navy duration-300;
	}

	.tag-pill-reversed {
		@apply tag-pill text-beige bg-navy;
	}

	.prose ul {
		@apply dash-list;
	}

	.prose {
		@apply prose-h3:text-lg lg:prose-h3:text-2xl prose-h3:font-medium;
	}

	.dash-list > li {
		position: relative;
		padding-left: 1em;
	}

	.dash-list > li::before {
		content: "–"; /* endash */
		position: absolute;
		left: 0;
		top: 0;
	}

	.caption a {
		@apply underline transition-colors underline-offset-2 hover:decoration-pink;
	}
}

.tag-pill:hover {
	@apply bg-navy text-beige;
}

.responsive-object {
	position: relative;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.menu-padding-top {
	padding-top: 8vh;
	/* short screens */
	@media screen and (min-height: 768px) and (min-width: 1023px) {
		padding-top: 8rem;
	}
}

.webkit-overflow-scrolling-touch {
	-webkit-overflow-scrolling: touch;
}
